import Func from "@/config/static/func";
import LoginRouter from "@/pages/login/router";
import store from "@/store/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Vue from "vue";
import VueRouter from "vue-router";
import tokenFunc from "@/config/static/token";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Index",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      leftNav: false,
      auth: [1, 2, 3, 4],
    },
    beforeEnter: (to, from, next) => {
      let loginInfo = store.getters.loginInfo;
      if (loginInfo && loginInfo.is_open === 1) {
        next("/template");
      } else {
        next();
      }
    },
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/Home/index.vue"),
  },
  {
    path: "/home-setting",
    name: "HomeSetting",
    meta: {
      leftNav: true,
      auth: [1, 2, 3],
    },
    component: () =>
      import(
        /* webpackChunkName: "HomeSetting" */
        "../views/HomeSetting/index.vue"
      ),
    beforeEnter: (to, from, next) => {
      let loginInfo = store.getters.loginInfo;
      if (loginInfo && loginInfo.current_team_is_expired === 2) {
        next("/template");
      } else {
        next();
      }
    },
  },
  {
    path: "/member",
    name: "Member",
    meta: {
      leftNav: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(/* webpackChunkName: "Member" */ "../views/Member/index.vue"),
  },
  {
    path: "/statistics",
    name: "Statistics",
    meta: {
      leftNav: true,
      auth: [1, 2],
    },
    component: () =>
      import(
        /* webpackChunkName: "Statistics" */
        "../views/Statistics/index.vue"
      ),
  },
  {
    path: "/template",
    name: "Template",
    meta: {
      leftNav: true,
      expand: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(
        /* webpackChunkName: "Template" */
        "../views/TeamTemplate/index.vue"
      ),
  },
  {
    path: "/brand",
    name: "Brand",
    meta: {
      leftNav: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(/* webpackChunkName: "Brand" */ "../views/Brand/index.vue"),
  },
  {
    path: "/material",
    name: "Material",
    meta: {
      leftNav: true,
      expand: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(/* webpackChunkName: "Material" */ "../views/Material/index.vue"),
  },
  {
    path: "/template_list",
    name: "TemplateList",
    meta: {
      leftNav: false,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(
        /* webpackChunkName: "Template" */
        "../views/TemplateList/index.vue"
      ),
  },
  {
    path: "/imagelist",
    name: "ImageList",
    meta: {
      leftNav: false,
      auth: [1, 2],
    },
    component: () =>
      import(
        /* webpackChunkName: "Template" */
        "../views/ImageList/index.vue"
      ),
  },
  {
    path: "/user",
    name: "User",
    meta: {
      leftNav: false,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(
        /* webpackChunkName: "User" */
        "../views/userConfig/index.vue"
      ),
  },
  {
    path: "/design",
    name: "Space",
    meta: {
      leftNav: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(/* webpackChunkName: "Material" */ "../views/Space/index.vue"),
  },
  {
    path: "/collect",
    name: "Collect",
    meta: {
      leftNav: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(/* webpackChunkName: "Material" */ "../views/Collect/index.vue"),
  },
  {
    path: "/templateCenter",
    name: "TemplateCenter",
    meta: {
      leftNav: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(
        /* webpackChunkName: "Material" */ "../views/TemplateCenter/index.vue"
      ),
  },
  {
    path: "/copyright",
    name: "Copyright",
    meta: {
      leftNav: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(/* webpackChunkName: "Material" */ "../views/Copyright/index.vue"),
  },
  {
    path: "/templateCenterDetail",
    name: "TemplateCenterDetail",
    meta: {
      leftNav: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(
        /* webpackChunkName: "Material" */ "../views/TemplateCenter/components/templateDetail.vue"
      ),
  },
  {
    path: "/authorization",
    name: "Authorization",
    meta: {
      leftNav: false,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(
        /* webpackChunkName: "Authorization" */ "../views/Copyright/authorization.vue"
      ),
  },
  {
    path: "/demand",
    name: "Demand",
    meta: {
      leftNav: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(
        /* webpackChunkName: "Authorization" */ "../views/TemplateCenter/components/demand.vue"
      ),
  },
  {
    path: "/createdesign",
    name: "Createdesign",
    meta: {
      leftNav: true,
      auth: [1, 2, 3, 4],
    },
    component: () =>
      import(
        /* webpackChunkName: "Authorization" */ "../views/Createdesign/index.vue"
      ),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  tokenFunc.proxyInfo(callback)
  function callback() {
    NProgress.start();
    const token = tokenFunc.getToken();
    if (token) {
      let team = store.dispatch("changeTeam");
      team.then(async (res) => {
          if (res === 2) {
            let access_token = tokenFunc.getToken();
            let loginRes = loginSdk.requestAction("logout", {
              token: access_token,
            });
            if (loginRes.code == 0) {
              window.sessionStorage.clear();
              store.commit("loginInfo", null);
              // 如果没有邀请信息
              if (window.location.href.indexOf("invite") === -1) {
                Vue.prototype.staticFunc.toHref(process.env.VUE_APP_BASELogout);
              }
            }
            window.location.href = process.env.VUE_APP_BASELogout;
            return;
          }
          if (res === 0) {
            // 如果 === 0 则代表不是本团队的或者默认新用户一直无团队， 就需要强制跳转出去
            if (process.env.NODE_ENV === "production") {
              store.dispatch("loginInfo").then((data) => {
                if (data) {
                  let host = window.location.host;
                  let teamList = store.getters.teamList;
                  let findObj = teamList.find(
                    // 判断如果当前访问的域名在团队列表中不存在 则需要跳转到当前域名去
                    (team) => {
                      let hostStr = host.split(".")[0];
                      let str = team.domain.split("//")[1].split(".")[0];
                      return str.toUpperCase() === hostStr.toUpperCase();
                    }
                  );
                  if (!findObj) {
                    let url = process.env.VUE_APP_BASELogout;
                    window.location.href = url.substr(0, url.length - 6);
                    return false;
                  }
                  if (data.current_team_id === 0) {
                    let url = process.env.VUE_APP_BASELogout;
                    window.location.href = url.substr(0, url.length - 6);
                  }
                  if (
                    data.current_team_is_expired == 2 &&
                    to.name === "HomeSetting"
                  ) {
                    next("/template");
                    NProgress.done();
                    return;
                  }
                  if (data.is_open === 1 && to.name === "Home") {
                    next("/template");
                  } else {
                    // next();
                    let cloneNavList = Func.flatData(routes, "children");
                    let toInfo = cloneNavList.find(
                      (item) => item.path && item.path === to.path
                    );
                    if (
                      toInfo &&
                      toInfo.meta.auth.includes(data.current_team_role)
                    ) {
                      next();
                    } else {
                      if (data.is_open === 1) {
                        next("/home");
                      } else {
                        next("/template");
                      }
                    }
                  }
                } else {
                  Vue.prototype.$router.push({ name: "Landing" });
                }
              });
            } else {
              store.dispatch("loginInfo").then((data) => {
                if (data.current_team_id === 0) {
                  Vue.prototype.staticFunc.removeCookie("user_uuid");
                  window.location.href = location.origin + "/login.html";
                  // localStorage.removeItem("token");
                  window.localStorage.clear();
                  window.sessionStorage.clear();
                }
  
                if (process.env.NODE_ENV != "production") {
                  let loginRouter = LoginRouter.options.routes.splice(
                    1,
                    LoginRouter.options.routes.length - 2
                  );
                  let str = "";
                  for (let i = 0; i < loginRouter.length; i++) {
                    if (loginRouter[i].path === to.path)
                      str = loginRouter[i].path;
                  }
                  if (str) {
                    window.location.href =
                      location.origin + "/login.html?path=" + str;
                  } else {
                    if (
                      data.current_team_is_expired == 2 &&
                      to.name === "HomeSetting"
                    ) {
                      next("/template");
                      NProgress.done();
                      return;
                    }
                    if (data.is_open === 1 && to.name === "Home") {
                      next("/template");
                    } else {
                      let cloneNavList = Func.flatData(routes, "children");
                      let toInfo = cloneNavList.find(
                        (item) => item.path && item.path === to.path
                      );
                      if (
                        toInfo &&
                        toInfo.meta.auth.includes(data.current_team_role)
                      ) {
                        next();
                      } else {
                        if (data.is_open === 1) {
                          next("/home");
                        } else {
                          next("/template");
                        }
                      }
                    }
                  }
                }
              });
            }
          } else {
            store.dispatch("loginInfo").then((data) => {
              if (data.is_open === 1 && to.name === "Home") {
                next("template");
              } else {
                // next();
                let cloneNavList = Func.flatData(routes, "children");
                let toInfo = cloneNavList.find(
                  (item) => item.path && item.path === to.path
                );
                if (
                  toInfo &&
                  toInfo.meta.auth.includes(data.current_team_role)
                ) {
                  next();
                } else {
                  if (data.is_open === 1) {
                    next("/home");
                  } else {
                    next("/template");
                  }
                }
              }
            });
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    } else {
      Vue.prototype.staticFunc.toHref("/login.html");
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
export default router;
