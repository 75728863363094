<template>
  <div class="register-modal" :style="status === 'success' ? {'height': '462px'} : {'height': '630px'}">
    <span class="iconfont icon-no close_button" @click="$emit('close')"></span>
    <h1 v-if="status === 'get'">注册</h1>
    <div v-if="status === 'get'" class="register-modal-inner">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="login-form"
        label-width="0"
        @keyup.enter.native="register"
        autocomplete="off"
      >
        <el-form-item label="" prop="mobile">
          <el-input
            type="text"
            v-model="ruleForm.mobile"
            auto-complete="off"
            class="login-input pass-input"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="code">
          <el-input
            style="width: 234px;"
            type="text"
            placeholder="图形验证码"
            class="login-input pass-input"
            v-model="ruleForm.code"
            auto-complete="new-password"
          ></el-input>
          <img :src="url" alt="" @click="getImage" />
        </el-form-item>
        <el-form-item label="" prop="message_code">
          <el-input
            style="width: 234px;"
            type="text"
            placeholder="短信验证码"
            class="login-input pass-input"
            v-model="ruleForm.message_code"
            auto-complete="new-password"
          ></el-input>
          <el-button
            style="width: 106px; border-radius: 0 4px 4px 0"
            type="primary"
            class="send-btn"
            @click="smsSendCode"
            :disabled="timer"
            >{{ timer ? `(${count})s重新发送` : "获取验证码" }}</el-button
          >
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            auto-complete="new-password"
            class="login-input pass-input"
            placeholder="新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="confirm_password">
          <el-input
            type="password"
            v-model="ruleForm.confirm_password"
            auto-complete="off"
            class="login-input pass-input"
            placeholder="再次确认"
          ></el-input>
        </el-form-item>
        <el-form-tem class="submit-item">
          <el-button
            style="width: 340px"
            class="submit-btn"
            type="primary"
            @click="register"
            :disabled="loginStatus"
            :loading="loginStatus"
          >
            注册
          </el-button>
        </el-form-tem>
      </el-form>
    </div>
    <div v-if="status === 'get'" class="login-way">
      <div class="login-way-item" @click="$emit('changeWay')">
        <div class="login-way-icon wechat"></div>
        <div class="login-way-font">微信登录</div>
      </div>
      <div class="login-way-item" @click="$parent.getQQLogin">
        <div class="login-way-icon qq"></div>
        <div class="login-way-font">QQ登录</div>
      </div>
      <div class="login-way-item" @click="$parent.getWeiboLogin">
        <div class="login-way-icon weibo"></div>
        <div class="login-way-font">微博登录</div>
      </div>
      <!-- <div class="login-way-item" @click="$parent.getQiyeLogin">
        <div class="login-way-icon qywechat"></div>
        <div class="login-way-font">企业微信</div>
      </div> -->
    </div>
    <div v-if="status === 'get'" class="login-bar">
      <span>已有账号</span><el-button type="text" @click="$emit('toLogin')">立即登录</el-button>
    </div>
    <div v-if="status === 'success'" class="register-modal-inner">
      <img class="success-icon" src="@/assets/image/logindialog/success.png" alt="" />
      <p>注册成功</p>
      <el-button style="width: 340px" type="primary" @click="$emit('changeDialogType')">返回登录</el-button>
    </div>
  </div>
</template>

<script>
import { Login } from "@/config/axios/landing";
let production = process.env.NODE_ENV === "production";

export default {
  name: "register",
  data() {
    var validateMobile = (rule, value, callback) => {
      let reg = /^1[0-9]{10}/;
      // let reg1 = /[a-zA-Z0-9]{1,10}@[a-zA-Z0-9]{1,5}\.[a-zA-Z0-9]{1,5}/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        var reg = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[\.,#?!@$%^&*-].*)[0-9A-Za-z\.,#?!@$%^&*-]{12,32}$/;
        if (!reg.test(this.ruleForm.password)) {
          callback(new Error("密码由12-32位数字、大/小写字母、常用特殊字符组成"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      status: "get", // get success
      url: "", // 图形
      timer: null,
      count: 0,
      ruleForm: {
        mobile: "",
        message_code: "",
        password: "",
        confirm_password: "",
        sms_type: "register",
        code_key: "",
        code: "",
      },
      loginStatus: false, // 点击按钮请求是， 需禁用
      loginParams: {
        // 后期用于统计注册操作 来源主要通过url
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
        message_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        confirm_password: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  created() {
    this.getImage();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    this.count = 0;
  },
  methods: {
    getImage() {
      Login.imageCode({
        random: Math.random(),
        captcha_type: "register",
      }).then((res) => {
        this.url = res.data.content;
        this.ruleForm.code_key = res.data.key;
      });
    },
    smsSendCode() {
      let validateList = [];
      this.$refs["ruleForm"].validateField(["mobile", "code"], (msg) => {
        validateList.push(msg);
      });
      // 全符合规则
      if (validateList.every((item) => item === "")) {
        Login.smsSendCodeRegister({
          mobile: this.ruleForm.mobile,
          code: this.ruleForm.code,
          sms_type: this.ruleForm.sms_type,
          code_key: this.ruleForm.code_key,
        })
          .then((res) => {
            if (res.status === 1) {
              const TIME = 60;
              clearInterval(this.timer);
              this.timer = null;
              this.count = TIME;
              this.timer = setInterval(() => {
                if (this.count > 1 && this.count <= TIME) {
                  this.count--;
                } else {
                  clearInterval(this.timer);
                  this.timer = null;
                  this.count = 0;
                }
              }, 1000);
            }
          })
          .catch(() => {
            this.getImage();
          });
      }
    },
    register() {
      this.loginStatus = true;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          Login.register(
            Object.assign(
              {
                mobile: this.ruleForm.mobile,
                message_code: this.ruleForm.message_code,
                password: this.ruleForm.password,
                confirm_password: this.ruleForm.confirm_password,
                sms_type: this.ruleForm.sms_type,
              },
              this.loginParams
            )
          )
            .then((res) => {
              this.loginStatus = false;
              if (res.status === 1) {
                this.status = "success";
              }
            })
            .catch((err) => {
              this.loginStatus = false;
            });
        } else {
          this.loginStatus = false;
          return false;
        }
      });
    },
    created() {
      let { utm_source, utm_medium, utm_campaign } = this.$route.query;
      this.loginParams = {
        utm_source: utm_source || "",
        utm_medium: utm_medium || "",
        utm_campaign: utm_campaign || "",
      };
    },
    submit() {
      let that = this;
      // this.loginStatus = true;
      this.next = true;
      // this.$refs["findRuleForm"].validate((valid) => {
      //   if (valid) {
      //     let { mobile, password } = this.ruleForm;
      //     let submitData = {
      //       password,
      //     };
      //     if (mobile.indexOf("@") !== -1) {
      //       submitData.mail = mobile;
      //     } else {
      //       submitData.mobile = mobile;
      //     }
      //     if (submitData.mail) {
      //       Login.emailLogin(submitData).then((res) => {
      //         setLoginData(res);
      //       });
      //     } else {
      //       Login.passwordLogin(submitData).then((res) => {
      //         setLoginData(res);
      //       });
      //     }
      //   } else {
      //     this.loginStatus = false;
      //   }
      // });

      function setLoginData(res) {
        that.loginStatus = false;
        if (res.status) {
          if (res.data && res.data.user.id) {
            clearInterval(that.$parent.getWeixinLogin);
            that.$message.success("登录成功");
            that.$store.commit("loginInfo", res.data.user);
            console.log(
              res.data.user.current_team_domain,
              " res.data.user.current_team_domain"
            );

            if (!production) {
              that.staticFunc.setCookie("user_uuid", res.data.user.uuid);
              that.$router.push({ name: "Landing" });
            } else {
              let domain = res.data.user.current_team_domain;
              if (process.env.VUE_APP_RealmName) {
                // 配置域名
                domain =
                  "https://" +
                  domain.split("//")[1].split(".")[0] +
                  `.${process.env.VUE_APP_RealmName}`;
              }
              if (domain) {
                window.location.href = domain;
              } else {
                that.$router.push({ name: "Team" });
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 400px;
  height: 630px;
  box-sizing: border-box;
  padding-top: 40px;
  .close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 26px;
    color: #999;
  }
  h1 {
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3B3946;
    line-height: 30px;
    margin-bottom: 22px;
    text-align: center;
  }
  .register-modal-inner {
    width: 340px;
    margin: 0 auto;
    .success-icon {
      width: 52px;
      height: 52px;
      display: block;
      margin: 31px auto 0;
    }
    p {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      line-height: 30px;
      text-align: center;
      margin: 34px auto 22px;
    }
    ::v-deep(.el-form-item) {
      margin-bottom: 25px;
      img {
        width: 106px;
        height: 40px;
        vertical-align: bottom;
        border-radius: 0px 4px 4px 0px;
      }
    }
    .pass-input {
      ::v-deep(.el-input__inner) {
        border-radius: 4px 0 0 4px;
      }
    }
    ::v-deep(.send-btn.is-disabled) {
      padding: 12px 0;
    }
  }
  .login-way {
    position: absolute;
    left: 50%;
    bottom: 81px;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    .login-way-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 40px;
      height: 40px;
      margin-right: 30px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      .login-way-icon {
        width: 100%;
        height: 100%;
        &.phone {
          background: url("../../../../assets/image/logindialog/phone.png");
          background-size: 100%;
          &.active,
          &:hover {
            background: url("../../../../assets/image/logindialog/phoneh.png");
            background-size: 100%;
          }
        }
        &.wechat {
          background: url("../../../../assets/image/logindialog/weixin.png");
          background-size: 100%;
          &.active,
          &:hover {
            background: url("../../../../assets/image/logindialog/weixinh.png");
            background-size: 100%;
          }
        }
        &.qq {
          background: url("../../../../assets/image/logindialog/qq.png");
          background-size: 100%;
          &.active,
          &:hover {
            background: url("../../../../assets/image/logindialog/qqh.png");
            background-size: 100%;
          }
        }
        &.weibo {
          background: url("../../../../assets/image/logindialog/weibo.png");
          background-size: 100%;
          &.active,
          &:hover {
            background: url("../../../../assets/image/logindialog/weiboh.png");
            background-size: 100%;
          }
        }
        &.qywechat {
          background: url("../../../../assets/image/logindialog/qyweChat.png");
          background-size: 100%;
          &.active,
          &:hover {
            background: url("../../../../assets/image/logindialog/qyweChath.png");
            background-size: 100%;
          }
        }
      }
      .login-way-font {
        position: absolute;
        bottom: -23px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3b3946;
        line-height: 17px;
      }
    }
  }
  .login-bar {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 37px;
    background: #F7F7F7;
    border-radius: 0px 0px 8px 8px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    ::v-deep(.el-button) {
      font-size: 12px;
      margin-top: 2px;
    }
  }
}

</style>
