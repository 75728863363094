<template>
  <div class="sms-login-wrapper">
    <div class="sms-login-inner">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="smsRuleForm"
        class="login-form"
        label-width="0"
        @keyup.enter.native="submit"
        autocomplete="off"
      >
        <el-form-item label="" prop="mobile">
          <el-input
            type="text"
            v-model="ruleForm.mobile"
            auto-complete="off"
            class="login-input pass-input"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="sms_code">
          <el-input
            style="width: 234px;"
            type="text"
            placeholder="短信验证码"
            class="login-input pass-input"
            v-model="ruleForm.sms_code"
            autocomplete="new-password"
          ></el-input>
          <el-button
            class="send-btn"
            style="width: 106px; border-radius: 0 4px 4px 0"
            type="primary"
            @click="sendSmsCode"
            :disabled="timer"
            >{{ timer ? `(${count})s重新发送` : "获取验证码" }}</el-button
          >
        </el-form-item>
        <el-form-tem class="submit-item">
          <el-button
            style="width: 340px"
            class="submit-btn"
            type="primary"
            @click="submit"
            :disabled="loginStatus"
            :loading="loginStatus"
          >
            登录
          </el-button>
        </el-form-tem>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Login } from "@/config/axios/landing";
let production = process.env.NODE_ENV === "production";

export default {
  name: "smsLogin",
  data() {
    var validateMobile = (rule, value, callback) => {
      let reg = /^1[0-9]{10}/;
      // let reg1 = /[a-zA-Z0-9]{1,10}@[a-zA-Z0-9]{1,5}\.[a-zA-Z0-9]{1,5}/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      }
      callback();
    };
    return {
      count: 0,
      timer: null,
      ruleForm: {
        mobile: "",
        sms_code: "", // 手机验证码
        sms_type: "login",
      },
      loginStatus: false, // 点击按钮请求是， 需禁用
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
        sms_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    // 发送短信
    sendSmsCode() {
      this.$refs["smsRuleForm"].validateField("mobile", (msg) => {
        if (!msg) {
          Login.smsCode({
            mobile: this.ruleForm.mobile,
          }).then((res) => {
            if (res.status === 1) {
              const TIME = 60;
              clearInterval(this.timer);
              this.timer = null;
              this.count = TIME;
              this.timer = setInterval(() => {
                if (this.count > 1 && this.count <= TIME) {
                  this.count--;
                } else {
                  clearInterval(this.timer);
                  this.timer = null;
                  this.count = 0;
                }
              }, 1000);
            }
          });
        }
      });
    },
    submit() {
      this.loginStatus = true;
      this.$refs["smsRuleForm"].validate((valid) => {
        if (valid) {
          Login.smsLogin(this.ruleForm).then((res) => {
            this.loginStatus = false;
            if (res.status) {
              if (res.data && res.data.user.id) {
                this.$message.success("登录成功");
                clearInterval(this.timer);
                this.timer = null;
                this.$store.commit("loginInfo", res.data.user);
                if (!production) {
                  this.staticFunc.setCookie("user_uuid", res.data.user.uuid);
                }
                this.$parent.afterLoginOperate();
                // this.$router.push({ name: "Team" });
                // window.location.href = "index.html";
              }
            }
          });
        } else {
          this.loginStatus = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sms-login-wrapper {
  padding-top: 30px;
  .sms-login-inner {
    width: 340px;
    margin: 0 auto;
    ::v-deep(.el-form-item) {
      margin-bottom: 25px;
    }
    .pass-input {
      ::v-deep(.el-input__inner) {
        border-radius: 4px 0 0 4px;
      }
    }
    ::v-deep(.send-btn.is-disabled) {
      padding: 12px 0;
    }
  }
}
</style>
