<template>
  <!--团队过期操作弹出框-->
  <el-dialog title="当前企业VIP已到期，请前往续费" :visible.sync="$parent.expiresStatus" class="del-dialog" @close="close">
    <span slot="footer" class="dialog-footer btn-wrap expires">
      <el-button type="primary" @click.stop="expiresDialogSubmit" style="margin: 0 atuo"> 去续费 </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  methods: {
    expiresDialogSubmit() {
      window.open(process.env.VUE_APP_BASELogout.substr(0, process.env.VUE_APP_BASELogout.length - 6) + '/contact');
    },
    close() {
      if (this.$route.name === "HomeSetting") {
        this.$router.push({ path: "/template" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.expires button {
  margin: 0 auto;
}
.del-dialog {
  ::v-deep(.el-dialog__header) {
    width: 236px;
  }
}
</style>
