<template>
  <div id="app">
    <!--有些页面不需要左部和header  需要根据情况进行判断-->
    <CHeader v-if="!$route.meta.header" />
    <LeftNav v-if="$route.meta.leftNav" />
    <router-view :style="style" />
  </div>
</template>
<script>
import CHeader from "@/components/common/header.vue";
import LeftNav from "@/components/common/leftNav.vue";
import { getTeam, Member } from "@/config/axios/new_api";
import tokenFunc from '@/config/static/token'
export default {
  components: { LeftNav, CHeader },
  computed: {
    style() {
      let str = !this.$route.meta.leftNav ? "marginLeft: 0;paddingTop: 60px" : "marginLeft: 200px;paddingTop: 60px";
      if (this.$route.meta.header) {
        str = "marginLeft: 0;paddingTop: 0";
      }
      return str;
    },
  },
  mounted () {
    if (tokenFunc.getToken()) {
      this.getCurrentTeam();
      this.getData();
    }
  },
  methods: {
    getData() {
      if (!tokenFunc.getToken()) {
        console.log("No token")
        window.location.href = process.env.VUE_APP_BASELogout + window.location.search;
        return;
      }
      getTeam().then((res) => {
        if (!res.data || !res.data.length) {
          // 需要跳转其他页面
          if (process.env.NODE_ENV === "production") {
            let url = process.env.VUE_APP_BASELogout;
            window.location.href = url.substr(0, url.length - 6);
          }
        }
        let list = res.data;
        this.$store.commit("teamList", list);
      });
    },
    async getCurrentTeam() {
      let res = await Member.getCurrentTeam();
      if (res.status == 1) {
        this.$store.commit("changeCurrentTeam", res.data);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/css/edit-ele.scss";
@import "@/assets/css/common.scss";
</style>
