<template>
  <div class="modal_mask">
    <div class="login-modal" v-if="dialogType === 'login'">
      <span
        class="iconfont icon-no close_button"
        @click="$emit('close')"
      ></span>
      <div class="login-modal-left">
        <img src="https://www.isheji.com/new/image/login/leftImg3.png" alt="" />
      </div>
      <div class="login-modal-right">
        <h1>{{ loginTitle }}</h1>
        <!-- 微信登录 -->
        <wechat-login  v-if="wayType === 'wechat'" :wayType.sync="wayType" @close="$emit('close')" />
        <!-- 手机号登录 -->
        <phone-login v-if="wayType === 'mobile'" />
        <!-- 短信验证码登录 -->
        <sms-login v-if="wayType === 'sms'" />
        <!-- 账号密码登录 -->
        <accountLogin v-if="wayType === 'account'" />
        <div
          class="phone-chang-bar"
          :style="
            wayType === 'account'
              ? { 'justify-content': 'flex-end' }
              : { 'justify-content': 'space-between' }
          "
          v-if="
            wayType === 'mobile' || wayType === 'sms' || wayType === 'account'
          "
        >
          <el-button
            type="text"
            v-show="wayType !== 'account'"
            @click="changWayAccountOrSms"
            >{{
              loginTitle === "短信验证码登录"
                ? "手机密码登录"
                : "短信验证码登录"
            }}</el-button
          >
          <el-button type="text" @click="dialogType = 'find'"
            >忘记密码？</el-button
          >
        </div>
        <div class="login-way">
          <div
            class="login-way-item"
            v-if="wayType === 'wechat'"
            @click="wayType = 'mobile'"
          >
            <div class="login-way-icon phone"></div>
            <div class="login-way-font">手机号登录</div>
          </div>
          <div
            class="login-way-item"
            v-if="wayType !== 'wechat'"
            @click="wayType = 'wechat'"
          >
            <div class="login-way-icon wechat"></div>
            <div class="login-way-font">微信登录</div>
          </div>
          <div class="login-way-item" @click="getQQLogin">
            <div class="login-way-icon qq"></div>
            <div class="login-way-font">QQ登录</div>
          </div>
          <div class="login-way-item" @click="getWeiboLogin">
            <div class="login-way-icon weibo"></div>
            <div class="login-way-font">微博登录</div>
          </div>
          <!-- <div class="login-way-item" @click="getQiyeLogin">
            <div class="login-way-icon qywechat"></div>
            <div class="login-way-font">企业微信</div>
          </div> -->
        </div>
        <div class="bottom-tip">
                    <section>登录即代表同意<span><a href="https://www.isheji.com/helpercenter?straight_id=yhxy" target="_blank">用户协议</a>、<a href="https://www.isheji.com/helpercenter?straight_id=yszc" target="_blank">隐私政策</a>、<a href="https://www.isheji.com/helpercenter?straight_id=sqxk" target="_blank">授权许可协议</a></span>，如果您成为</section>
                    <section>爱设计会员则视为同意<span><a href="https://www.isheji.com/helpercenter?straight_id=hyfw" target="_blank">会员服务协议</a></span></section>
                </div>
      </div>
      
      
    </div>
    <!-- 找回密码 -->
    <find-password
      v-if="dialogType === 'find'"
      @close="$emit('close')"
      @changeDialogType="dialogType = 'login'"
    />
    <!-- 注册 -->
    <register
      v-if="dialogType === 'register'"
      @close="$emit('close')"
      @changeWay="
        dialogType = 'login';
        wayType = 'wechat';
      "
      @toLogin="dialogType = 'login'"
      @changeDialogType="dialogType = 'login'"
    />
  </div>
</template>

<script>
import wechatLogin from "@/components/common/components/logindialog/wechatLogin";
import accountLogin from "@/components/common/components/logindialog/accountLogin";
import phoneLogin from "@/components/common/components/logindialog/phoneLogin";
import smsLogin from "@/components/common/components/logindialog/smsLogin";
import findPassword from "@/components/common/components/logindialog/findPassword";
import register from "@/components/common/components/logindialog/register";
import { Login, UserInfo } from "@/config/axios/landing";
// let production = process.env.NODE_ENV === "production";

export default {
  name: "loginDialog",
  props: {
    operateSource: {
      type: String,
    },
  },
  data() {
    return {
      close: false,
      dialogType: "login",
      wayType: "wechat",
      loginParams: {
        // 后期用于统计注册操作 来源主要通过url
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
      },
    };
  },
  computed: {
    loginTitle() {
      let str = "";
      switch (this.wayType) {
        case "wechat":
          str = "微信扫一扫登录";
          break;
        case "mobile":
          str = "手机号登录";
          break;
        case "sms":
          str = "短信验证码登录";
          break;
        case "account":
          str = "账号密码登录";
          break;
        default:
          break;
      }
      return str;
    },
  },
  components: {
    wechatLogin,
    accountLogin,
    phoneLogin,
    smsLogin,
    findPassword,
    register,
  },
  created() {
    let { utm_source, utm_medium, utm_campaign } = this.$route.query;
    this.loginParams = {
      utm_source: utm_source || "",
      utm_medium: utm_medium || "",
      utm_campaign: utm_campaign || "",
    };
  },
  mounted() {
    // document.body.classList.add("el-popup-parent--hidden");
    // document.body.style.paddingRight = "15px";
  },
  destroyed() {
    // document.body.classList.remove("el-popup-parent--hidden");
    // document.body.style = "";
  },
  inject: ["openPayDialog"],
  methods: {
    changWayAccountOrSms() {
      if (this.wayType === "sms") {
        this.wayType = "mobile";
      } else if (this.wayType === "mobile") {
        this.wayType = "sms";
      }
    },
    getQQLogin() {
      Login.qqLogin({
        ...this.loginParams,
        edition: 1,
      }).then((res) => {
        if (res.status === "1") {
          window.location.href = res.message;
        }
      });
    },
    getWeiboLogin() {
      Login.weiboLogin({
        ...this.loginParams,
        edition: 1,
      }).then((res) => {
        if (res.status === "1") {
          window.location.href = res.message;
        }
      });
    },
    getQiyeLogin() {
      window.location.href = process.env.VUE_APP_BaseHref + "?type=qychat";
    },
    toggleLogin(item) {
      let include = [4, 5, 6];
      if (!include.includes(item.type)) {
        this.loginT = item;
      }
      if (item.type === 3) {
        // 获取微信二维码
        this.getWechatCode();
      } else if (item.type === 4) {
        this.getQQLogin();
      } else if (item.type === 5) {
        this.getWeiboLogin();
      } else if (item.type === 6) {
        this.getQiyeLogin();
      }
    },
    // 登录后的操作
    afterLoginOperate() {
      this.dialogType = "";
      location.reload();
      if (this.$parent.$refs["loginInLanding"]) {
        switch (this.operateSource) {
          case "立即开通":
            setTimeout(() => {
              this.$parent.openPayDialog();
            }, 2000);
            break;
          case "登录企业版":
            break;
          default:
            // location.reload();
            break;
        }
      }
    },
    changeWayType(){
      this.wayType = 'account'
    }
  },
};
</script>

<style lang="scss" scoped>
.modal_mask {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  .login-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 700px;
    height: 450px;
    .close_button {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 26px;
      color: #999;
      cursor: pointer;
      z-index: 9;
    }
    .login-modal-left {
      width: 300px;
      height: 100%;
      img {
        width: 100%;
        border-radius: 8px 0 0 8px;
      }
    }
    .login-modal-right {
      position: relative;
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      padding-top: 31px;
      h1 {
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3B3946;
        line-height: 30px;
        text-align: center;
      }
      .phone-chang-bar {
        width: 340px;
        display: flex;
        align-items: center;
        margin: 5px auto 0;
      }
      .login-way {
        position: absolute;
        left: 50%;
        bottom: 100px;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        .login-way-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 40px;
          height: 40px;
          margin-right: 30px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          .login-way-icon {
            width: 100%;
            height: 100%;
            &.phone {
              background: url("../../assets/image/logindialog/phone.png");
              background-size: 100%;
              &.active,
              &:hover {
                background: url("../../assets/image/logindialog/phoneh.png");
                background-size: 100%;
              }
            }
            &.wechat {
              background: url("../../assets/image/logindialog/weixin.png");
              background-size: 100%;
              &.active,
              &:hover {
                background: url("../../assets/image/logindialog/weixinh.png");
                background-size: 100%;
              }
            }
            &.qq {
              background: url("../../assets/image/logindialog/qq.png");
              background-size: 100%;
              &.active,
              &:hover {
                background: url("../../assets/image/logindialog/qqh.png");
                background-size: 100%;
              }
            }
            &.weibo {
              background: url("../../assets/image/logindialog/weibo.png");
              background-size: 100%;
              &.active,
              &:hover {
                background: url("../../assets/image/logindialog/weiboh.png");
                background-size: 100%;
              }
            }
            &.qywechat {
              background: url("../../assets/image/logindialog/qyweChat.png");
              background-size: 100%;
              &.active,
              &:hover {
                background: url("../../assets/image/logindialog/qyweChath.png");
                background-size: 100%;
              }
            }
          }
          .login-way-font {
            position: absolute;
            bottom: -23px;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3b3946;
            line-height: 17px;
          }
        }
      }
      .login-change-font {
        position: absolute;
        bottom: 24px;
        right: 40px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5a2df5;
        line-height: 20px;
        cursor: pointer;
      }
      .bottom-tip{
            position: absolute;
            bottom: 0;
            height: 60px;
            background: #F7F7F7;
            border-radius: 0px 0px 8px 8px;
            display: flex;
            flex-direction: column;
            padding: 13px;
            text-align: left;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9DA3AC;
            line-height: 17px;
            padding-left: 27px;
            width: 100%;
            span{
              a{
                    color: #636C78;
                    text-decoration: underline;
              }
            }

      }
    }
    .register-bar {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      height: 37px;
      background: #f7f7f7;
      border-radius: 0px 0px 8px 8px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      ::v-deep(.el-button) {
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }
}
</style>
