const ctypeMap = new Map([
  [5, "wx-main"],
  [6, "wx-secondary"],
  [7, "wx-layout"],
  [8, "article-adorn"],
  [9, "wx-qrcode"],
  [10, "phone-poster"],
  [11, "e-commerce-poster"],
  [12, "day-signin"],
  [13, "phone-wallpaper"],
  [14, "invitation"],
  [15, "friendship-cover"],
  [16, "wx-qrcode-square"],
  [17, "article-adorn-vertical"],
  [18, "hyperlink"],
  [19, "emoticon"],
  [82, "course-cover"],
  [80, "marketing-long-img"],
  [81, "cross-edition-poster"],
  [96, "mobile-poster"],
  [97, "logo"],
  [98, "headimg"],
  [99, "rectposter"],
  [100, "vertical-draw"],
  [101, "side-video"],
  [102, "pc-wallpaper"],
  [103, "red-book"],
  [104, "book-cover"],
  [105, "video-cover"],
  [106, "online-icon"],
  [107, "online-main"],
  [108, "shop-recruit"],
  [109, "view-tip"],
  [110, "article-title"],
  [111, "cut-line"],
  [112, "wx-secondary-pro"],
  [114, "illustration-element"],
  [131, 'video-frame'],
  [132, 'live-background'],
  [133, 'live-element'],
  [134, 'live-pendant'],
  [135, 'capsule-banner'],
  [136, 'resume'],
  [137, 'word-template'],
  [0, "custom"],
]);
/**
 *
 * @param {number} key
 * @returns
 */
const cKey = (key) => ctypeMap.get(key);

// MODE 3 custom
export default {
  cKey,
};
