<template>
  <div class="find-modal">
    <span class="iconfont icon-no close_button" @click="$emit('close')"></span>
    <el-button
      v-if="status !== 'success'"
      style="width: 100px; height: 28px"
      icon="iconfont icon-moreandmore-copy-new-02-copy"
      class="el-button-text el-button-text--primary back-btn"
      @click="$emit('changeDialogType')"
      >返回登录</el-button
    >
    <h1 v-if="status !== 'success'">找回密码</h1>
    <div v-if="status === 'check'" class="find-modal-inner">
      <el-form
        :model="findForm1"
        :rules="findForm1Rules"
        ref="findForm1"
        class="login-form"
        label-width="0"
        @keyup.enter.native="findPasswordCheck"
        autocomplete="off"
      >
        <el-form-item label="" prop="mobile">
          <el-input
            type="text"
            v-model="findForm1.mobile"
            auto-complete="off"
            class="login-input pass-input"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="code">
          <el-input
            style="width: 234px"
            type="text"
            placeholder="图形验证码"
            class="login-input pass-input"
            v-model="findForm1.code"
            autocomplete="new-password"
          ></el-input>
          <img v-show="url" :src="url" @click="getImage" alt="" />
        </el-form-item>
        <el-form-item label="" prop="mobile_code">
          <el-input
            style="width: 234px"
            type="text"
            placeholder="短信验证码"
            class="login-input pass-input"
            v-model="findForm1.mobile_code"
            autocomplete="new-password"
          ></el-input>
          <el-button
            style="width: 106px; border-radius: 0 4px 4px 0"
            type="primary"
            class="send-btn"
            @click="smsSendCode"
            :disabled="timer"
            >{{ timer ? `(${count})s重新发送` : "获取验证码" }}</el-button
          >
        </el-form-item>
        <el-form-tem class="submit-item">
          <el-button
            style="width: 340px"
            class="submit-btn"
            type="primary"
            @click="findPasswordCheck"
            :disabled="loginStatus"
            :loading="loginStatus"
          >
            下一步
          </el-button>
        </el-form-tem>
      </el-form>
    </div>
    <div v-if="status === 'find'" class="find-modal-inner">
      <el-form
        :model="findForm2"
        :rules="findForm2Rules"
        ref="findForm2"
        class="login-form"
        label-width="0"
        @keyup.enter.native="resetPassword"
        autocomplete="off"
      >
        <el-form-item label="" prop="password">
          <el-input
            type="password"
            v-model="findForm2.password"
            auto-complete="off"
            class="login-input pass-input"
            placeholder="新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="confirm_password">
          <el-input
            type="password"
            v-model="findForm2.confirm_password"
            auto-complete="off"
            class="login-input pass-input"
            placeholder="再次确认"
          ></el-input>
        </el-form-item>
        <el-form-tem class="submit-item">
          <el-button
            style="width: 340px"
            class="submit-btn"
            type="primary"
            @click="resetPassword"
            :disabled="loginStatus"
            :loading="loginStatus"
          >
            确定
          </el-button>
        </el-form-tem>
      </el-form>
    </div>
    <div v-if="status === 'success'" class="find-modal-inner">
      <img class="success-icon" src="@/assets/image/logindialog/success.png" alt="" />
      <p>密码修改成功</p>
      <el-button style="width: 340px" type="primary" @click="$emit('changeDialogType')">返回登录</el-button>
    </div>
  </div>
</template>

<script>
import { Login } from "@/config/axios/landing";
let production = process.env.NODE_ENV === "production";

export default {
  name: "findPassword",
  data() {
    var validateMobile = (rule, value, callback) => {
      let reg = /^1[0-9]{10}/;
      // let reg1 = /[a-zA-Z0-9]{1,10}@[a-zA-Z0-9]{1,5}\.[a-zA-Z0-9]{1,5}/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        var reg = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[\.,#?!@$%^&*-].*)[0-9A-Za-z\.,#?!@$%^&*-]{12,32}$/;
        if (!reg.test(this.findForm2.password)) {
          callback(new Error("密码由12-32位数字、大/小写字母、常用特殊字符组成"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.findForm2.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      status: "check", // check find success
      url: "", // 图形
      timer: null,
      count: 0,
      findForm1: {
        mobile: "",
        mobile_code: "", // 手机验证码
        sms_type: "findPassword",
        code: "",
        code_key: "",
      },
      findForm2: {
        password: "",
        confirm_password: "",
      },
      loginStatus: false, // 点击按钮请求是， 需禁用
      findForm1Rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
        mobile_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
      },
      findForm2Rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        confirm_password: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  created() {
    this.getImage();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    this.count = 0;
  },
  watch: {
    status(val) {
      if (val !== "check") {
        clearInterval(this.timer);
        this.timer = null;
        this.count = 0;
      }
    },
  },
  methods: {
    getImage() {
      Login.imageCode({
        random: Math.random(),
        captcha_type: "findPassword",
      }).then((res) => {
        this.url = res.data.content;
        this.findForm1.code_key = res.data.key;
      });
    },
    smsSendCode() {
      let validateList = [];
      this.$refs["findForm1"].validateField(["mobile", "code"], (msg) => {
        validateList.push(msg);
      });
      // 全符合规则
      if (validateList.every((item) => item === "")) {
        Login.smsSendCode({
          mobile: this.findForm1.mobile,
          code: this.findForm1.code,
          sms_type: this.findForm1.sms_type,
          code_key: this.findForm1.code_key,
        })
          .then((res) => {
            if (res.status === 1) {
              const TIME = 60;
              clearInterval(this.timer);
              this.timer = null;
              this.count = TIME;
              this.timer = setInterval(() => {
                if (this.count > 1 && this.count <= TIME) {
                  this.count--;
                } else {
                  clearInterval(this.timer);
                  this.timer = null;
                  this.count = 0;
                }
              }, 1000);
            }
          })
          .catch(() => {
            this.getImage();
          });
      }
    },
    findPasswordCheck() {
      this.loginStatus = true;
      this.$refs["findForm1"].validate((valid) => {
        if (valid) {
          Login.findPasswordCheck({
            mobile: this.findForm1.mobile,
            mobile_code: this.findForm1.mobile_code,
          })
            .then((res) => {
              if (res.status === 1) {
                this.loginStatus = false;
                this.status = "find";
              }
            })
            .catch((err) => {
              this.loginStatus = false;
              this.getImage();
            });
        } else {
          this.loginStatus = false;
        }
      });
    },
    resetPassword() {
      this.loginStatus = true;
      this.$refs["findForm2"].validate((valid) => {
        if (valid) {
          Login.resetPassword({
            mobile: this.findForm1.mobile,
            password: this.findForm2.password,
            confirm_password: this.findForm2.confirm_password,
          })
            .then((res) => {
              this.loginStatus = false;
              if (res.status === 1) {
                this.status = "success";
              }
            })
            .catch((err) => {
              this.loginStatus = false;
            });
        } else {
          this.loginStatus = false;
          return false;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.find-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 400px;
  height: 426px;
  box-sizing: border-box;
  padding-top: 88px;
  .close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 26px;
    color: #999;
  }
  .back-btn {
    position: absolute;
    padding: 0;
    top: 20px;
    left: 20px;
  }
  ::v-deep(.back-btn) {
    .iconfont {
      font-size: 12px !important;
    }
  }
  h1 {
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3B3946;
    line-height: 30px;
    margin-bottom: 22px;
    text-align: center;
  }
  .find-modal-inner {
    width: 340px;
    margin: 0 auto;
    .success-icon {
      width: 52px;
      height: 52px;
      display: block;
      margin: 31px auto 0;
    }
    p {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      line-height: 30px;
      text-align: center;
      margin: 34px auto 22px;
    }
    ::v-deep(.el-form-item) {
      margin-bottom: 25px;
      img {
        width: 106px;
        height: 40px;
        vertical-align: bottom;
        border-radius: 0px 4px 4px 0px;
      }
    }
    .pass-input {
      ::v-deep(.el-input__inner) {
        border-radius: 4px 0 0 4px;
      }
    }
    ::v-deep(.send-btn.is-disabled) {
      padding: 12px 0;
    }
  }
}
</style>
