import "@/assets/css/style/theme/index.css";
import "@/assets/icon/font/iconfont.css";
import "@/config/directive";
import common from "@/config/mixin/common.js";
import designworkbench from "@/config/mixin/designworkbench";
import expireTeam from "@/config/mixin/expireTeam.js";
import staticFunc from "@/config/static/func";
import staticData from "@/config/static/map.js";
import Vue from "vue";
import VueCropper from "vue-cropper";
import waterfall from "vue-waterfall2";
import store from "../../store";
import App from "./App.vue";
import router from "./router";
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = true;
Vue.prototype.staticFunc = staticFunc;
Vue.prototype.staticData = staticData;
Vue.prototype.catchMessage = function(info){
  var error_type = typeof info;
	var msg;
	if(error_type == "string"){
		msg = info
	}
	if(error_type == "object"){
		if(info.data){
			msg = info.data.msg;
			if(info.data.code){
				msg += `(${info.data.code})`;
			}
		}else{
			msg = info.msg || info.error || info.message
		}
	}
  Vue.prototype.$message.error(msg);
}
Vue.prototype.$addStorageEvent = function (key, data) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
        setItem: function (k, val) {
            localStorage.setItem(k, val)
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
            // 派发对象
            window.dispatchEvent(newStorageEvent)
        },
    }
    return storage.setItem(key, data)
}
Vue.use(VueLazyload)
Vue.use(VueCropper);
Vue.use(waterfall);
// 全局混入过期会员操作
Vue.mixin(expireTeam);
// 全局混入模板跳转
Vue.mixin(designworkbench);
Vue.directive("loadmore", {
  bind(el, binding) {
    const selectWrap = el.querySelector(".el-table__body-wrapper");
    selectWrap.addEventListener("scroll", function () {
      let sign = 0;
      const scrollDistance =
        this.scrollHeight - this.scrollTop - this.clientHeight;
      if (scrollDistance <= sign) {
        binding.value();
      }
    });
  },
});

// 全局混入辅助函数
Vue.mixin(common);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
