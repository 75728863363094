import { Member } from "@/config/axios/new_api.js";
export default {
  computed: {
    loginInfo() {
      return this.$store.getters.loginInfo;
    },
  },
  methods: {
    // item, id type( template代表打开模板  design  代表从我的空间打开我的设计)
    goDesignworkbench(item, id, type = "template") {
      this.changeHrefTeam().then((status) => {
        if (status == 1) {
          let cw, ch, ckey
          if (Object.prototype.toString.call(item) === '[object Object]') {
            cw = item.size.split('x')[0]
            ch = item.size.split('x')[1]
            ckey = this.staticData.cKey(item.type)
          } else {
            ckey = item
          }
          var mode, source, href;

          let base64 = new setBase64Encry();
          let result = base64.encode(this.loginInfo.current_team_id + "");
          // let host = process.env.VUE_APP_BaseHref;
          // if (host.charAt(host.length - 1) === "/") {
          //   // 做一层兼容  兼容 可能会多写个/ 导致工作台保存不成功
          //   host = host.substr(0, host.length - 1);
          // }
          if (type == "template") {
            mode = item.type === 0 ? 3 : 2;
            source = "company_template";
            href = `/enterprise-design-v1?mode=${mode}&ckey=${ckey}&cw=${cw}&ch=${ch}&source=${source}&nature=team&natureid=${
              id || item.id
            }&md5=${result}`;
          }
          //我的空间 type为design   此时代表对某个设计进行编辑  故mode为1
          if (type == "design") {
            mode = 1;
            source = "space";
            href = `/enterprise-design-v1?mode=${mode}&ckey=${ckey}&cw=${cw}&ch=${ch}&source=${source}&nature=team&tid=${
              id || item.id
            }&md5=${result}`;
          }

          // todo 模板中心
          // mode=2&nature=xxx
          if (type === 'templatecenter') {
            mode = 2;
            source = "templatecenter";
            href = `/enterprise-design-v1?mode=${mode}&ckey=${ckey}&cw=${cw}&ch=${ch}&source=${source}&nature=templatecenter&tid=${
              id || item.id
			}&md5=${result}`
          }
          // 新建设计
          if (type === 'createDesign') {
            mode = 2
            href = `/enterprise-design-v1?mode=${mode}&ckey=${ckey}&nature=user&md5=${result}`
          }

            if (type === 'custom') {
				mode = 2
				href = `/enterprise-design-v1?mode=${mode}&ckey=${ckey}&cw=${cw}&ch=${ch}&nature=user&md5=${result}`
			}

          var a = document.createElement("a"); // 生成一个a元素
          var event = new MouseEvent("click"); // 创建一个单击事件
          a.href = href; // 将生成的URL设置为a.href属性
          a.setAttribute('target','_blank')
          a.dispatchEvent(event); // 触发a的单击事件
          // window.open(href);
        }
      });

      function setBase64Encry() {
        // private property
        let _keyStr =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

        // public method for encoding
        this.encode = function (input) {
          var output = "";
          var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
          var i = 0;
          input = _utf8_encode(input);
          while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
              enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
              enc4 = 64;
            }
            output =
              output +
              _keyStr.charAt(enc1) +
              _keyStr.charAt(enc2) +
              _keyStr.charAt(enc3) +
              _keyStr.charAt(enc4);
          }
          return output;
        };

        // public method for decoding
        this.decode = function (input) {
          var output = "";
          var chr1, chr2, chr3;
          var enc1, enc2, enc3, enc4;
          var i = 0;
          // eslint-disable-next-line no-useless-escape
          input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
          while (i < input.length) {
            enc1 = _keyStr.indexOf(input.charAt(i++));
            enc2 = _keyStr.indexOf(input.charAt(i++));
            enc3 = _keyStr.indexOf(input.charAt(i++));
            enc4 = _keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 != 64) {
              output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
              output = output + String.fromCharCode(chr3);
            }
          }
          output = _utf8_decode(output);
          return output;
        };

        // private method for UTF-8 encoding
        function _utf8_encode(string) {
          string = string.replace(/\r\n/g, "\n");
          var utftext = "";
          for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
              utftext += String.fromCharCode(c);
            } else if (c > 127 && c < 2048) {
              utftext += String.fromCharCode((c >> 6) | 192);
              utftext += String.fromCharCode((c & 63) | 128);
            } else {
              utftext += String.fromCharCode((c >> 12) | 224);
              utftext += String.fromCharCode(((c >> 6) & 63) | 128);
              utftext += String.fromCharCode((c & 63) | 128);
            }
          }
          return utftext;
        }

        // private method for UTF-8 decoding
        function _utf8_decode(utftext) {
          var string = "";
          // eslint-disable-next-line no-unused-vars
          var c1, c2, c3;
          var i = 0;
          var c = (c1 = c2 = 0);
          while (i < utftext.length) {
            c = utftext.charCodeAt(i);
            if (c < 128) {
              string += String.fromCharCode(c);
              i++;
            } else if (c > 191 && c < 224) {
              c2 = utftext.charCodeAt(i + 1);
              string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
              i += 2;
            } else {
              c2 = utftext.charCodeAt(i + 1);
              c3 = utftext.charCodeAt(i + 2);
              string += String.fromCharCode(
                ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
              );
              i += 3;
            }
          }
          return string;
        }
      }
    },
    changeHrefTeam () {
      console.log('changeHrefTeam')
      return new Promise((resolve) => {
        Member.getCurrentTeam({
          team_id: this.loginInfo.current_team_id,
        }).then((res) => {
          if (res.status == 1) {
            resolve(res.status);
          }
        });
      });
    },
  },
};
