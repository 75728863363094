<template>
  <div class="phone-login-wrapper">
    <div class="phone-login-inner">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="phoneRuleForm"
        class="login-form"
        label-width="0"
        @keyup.enter.native="submit"
        autocomplete="off"
      >
        <el-form-item label="" prop="mobile">
          <el-input
            type="text"
            v-model="ruleForm.mobile"
            auto-complete="off"
            class="login-input pass-input"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            type="password"
            placeholder="请输入密码"
            class="login-input pass-input"
            v-model="ruleForm.password"
            autocomplete="new-password"
          ></el-input>
        </el-form-item>
        <el-form-tem class="submit-item">
          <el-button
            style="width: 340px"
            class="submit-btn"
            type="primary"
            @click="submit"
            :disabled="loginStatus"
            :loading="loginStatus"
          >
            登录
          </el-button>
        </el-form-tem>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Login } from "@/config/axios/landing";
let production = process.env.NODE_ENV === "production";

export default {
  name: "phoneLogin",
  data() {
    var validateMobile = (rule, value, callback) => {
      let reg = /^1[0-9]{10}/;
      // let reg1 = /[a-zA-Z0-9]{1,10}@[a-zA-Z0-9]{1,5}\.[a-zA-Z0-9]{1,5}/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      }
      callback();
    };
    return {
      ruleForm: {
        mobile: "",
        password: "",
      },
      loginStatus: false, // 点击按钮请求是， 需禁用
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    submit() {
      let that = this;
      this.loginStatus = true;
      this.$refs["phoneRuleForm"].validate((valid) => {
        if (valid) {
          let { mobile, password } = this.ruleForm;
          let submitData = {
            password,
            mobile,
          };
          Login.passwordLogin(submitData).then((res) => {
            setLoginData(res);
          });
        } else {
          this.loginStatus = false;
        }
      });

      function setLoginData(res) {
        that.loginStatus = false;
        if (res.status) {
          if (res.data && res.data.user.id) {
            clearInterval(that.$parent.getWeixinLogin);
            that.$message.success("登录成功");
            that.$store.commit("loginInfo", res.data.user);
            // that.$parent.afterLoginOperate();
            if (!production) {
              that.staticFunc.setCookie("user_uuid", res.data.user.uuid);
              that.$router.push({ name: "Landing" });
            } else {
              let domain = res.data.user.current_team_domain;
              if (process.env.VUE_APP_RealmName) {
                // // 配置域名
                // domain =
                //   "https://" +
                //   domain.split("//")[1].split(".")[0] +
                //   `.${process.env.VUE_APP_RealmName}`;
                location.reload();
              }
              if (domain) {
                window.location.href = domain;
              } else {
                that.$router.push({ name: "Team" });
              }
            }
            that.$parent.afterLoginOperate();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-login-wrapper {
  padding-top: 30px;
  .phone-login-inner {
    width: 340px;
    margin: 0 auto;
    ::v-deep(.el-form-item) {
      margin-bottom: 25px;
    }
  }
}
</style>
