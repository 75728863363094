/**
 * 辅助性配置
 */

export default {
  methods: {
    buttonBlur(ev) {
      // el-button 在active之后 不会自动失焦， 需要进行此操作
      if ((ev.target.nodeName == "SPAN") | (ev.target.nodeName == "I")) {
        ev.target.parentNode.blur();
      } else {
        ev.target.blur();
      }
    },
    downExcelData(url, name) {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", name + ".xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
