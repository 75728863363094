import expireTeamDialog from "@/components/dialog/expireTeamDialog.vue";

export default {
  data() {
    return {
      expiresStatus: false,
    };
  },
  components: {
    expireTeamDialog,
  },
  methods: {
    expireHandle() {
      if (this.loginInfo.current_team_is_expired === 2) {
        this.expiresStatus = true;
        return false;
      }
      return true;
    },
  },
  computed: {
    loginInfo() {
      return this.$store.getters.loginInfo;
    },
  },
};
