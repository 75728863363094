import http from "./index";

const $ajax = (url, data, method = "GET", obj) => {
  let params = obj ? { url, method, data, ...obj } : { url, method, data };
  return http.httpServer(params);
};

export const Login = {
  passwordLogin: (data) => $ajax("/mobile/login", data, "POST"),
  smsLogin: (data) => $ajax("/sms/code/login", data, "POST"),
  smsCode: (data) => $ajax("/send/login/sms/code", data, "POST"),
  smsSendCode: (data) => $ajax("/sms/send/code", data, "POST"), // pc的通用发送验证码
  smsSendCodeRegister: (data) => $ajax("/send/message", data, "POST"), // pc的通用发送验证码
  imageCode: (data) => $ajax("/get/active/code/base64", data),
  //imageCode: "/get/active/code",
  wechatCode: (data) => $ajax("/wechat/code", data, "POST"), // 获取微信二维码
  wechatLogin: (data) => $ajax("/check/weixin/login", data, "POST"), // 轮询微信二维码
  qqLogin: (data) => $ajax("/qq/login", data),
  weiboLogin: (data) => $ajax("/weibo/login", data),
  findPassword: (data) => $ajax("/find/password", data, "POST"), // 忘记密码，进行重置
  findPasswordCheck: (data) => $ajax("/find/password/check", data, "POST"), // pc的检查密码
  resetPassword: (data) => $ajax("/reset/password", data, "POST"), // pc的找回密码
  register: (data) => $ajax("/mobile/register", data, "post"), // 注册
  logout: (data) => $ajax("/login/out", data, "POST"),
  smsFindCode: (data) => $ajax("/send/find_password/sms/code", data, "POST"),
  qiyeWechat: (data) => $ajax("enterprisewechat/goback/mainhost", data, "GET"),
  qiyeWechatLogin: (data) => $ajax("enterprisewechat/login", data),
  emailLogin: (data) => $ajax("/email/login", data, "POST"),
  emailCode: (data) => $ajax("send/login/email/code", data),
  emailCodeLogin: (data) => $ajax("email/code/login", data, "POST"),
};
export const postLogin = (data) => $ajax("/mobile/login", data, "POST");
export const codeLogin = (data) => $ajax("/sms/code/login", data, "POST");
export const getTeam = () => $ajax("/get/team/list");
export const changeTeam = (data) => $ajax("/change/current/team", data, "POST"); // 更新选中团队信息
export const UserInfo = (data) => $ajax("/user/info", data); // 获取用户信息
export const teamManager = (data) => $ajax("/team/manager", data); // 团队信息， 主要用于判断有木有自己的团队， 后期用于创建
export const checkTeam = (data) => $ajax("/check/current/team", data, "POST"); // 判断用户是否切换团队

export const Landing = {
  getVipPrice: (data) => $ajax("/get/company/vip/price", data, "POST"), // 获取企业vip价格
  getOrderNo: (data) =>
    $ajax("/company/vip/generate/merchant/order", data, "POST"), // 获取套餐生成单号
  getPayUrl: (data) => $ajax("/get/pay/url", data, "POST"), // 获取支付url
  getWxResult: (data) => $ajax("/wechat/result", data, "POST"), // 订单记录查询
  getApply: (data) => $ajax("/team/cooperate/apply", data, "POST"), // 提交方案
};
