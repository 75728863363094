export default [
  {
    index: 1,
    icon: "iconfont icon-wode",
    subicon: "el-icon-arrow-down",
    title: "我的空间",
    role: [1, 2, 3, 4],
    show: false,
    submenu: [
      {
        index: "1-1",
        icon: "iconfont icon-wode",
        title: "我的设计",
        role: [1, 2, 3, 4],
        to: "/design",
      },
      {
        index: "1-2",
        icon: "iconfont icon-shoucang",
        title: "我的收藏",
        role: [1, 2, 3, 4],
        to: "/collect",
      },
    ],
  },
  {
    icon: "iconfont icon-qiyekongjian-moren",
    subicon: "el-icon-arrow-down",
    title: "企业空间",
    role: [1, 2, 3, 4],
    show: false,
    index: 2,
    submenu: [
      {
        index: "2-1",
        icon: "iconfont icon-Side-navi-TeamStencil-unselection",
        title: "企业模板",
        role: [1, 2, 3, 4],
        to: "/template",
      },
      {
        index: "2-2",
        icon: "iconfont icon-Side-navi-TeamElement-unselection",
        title: "企业素材",
        role: [1, 2, 3, 4],
        to: "/material",
      },
    ],
  },
  {
    index: 3,
    icon: "iconfont icon-Side-navi-New-selection",
    title: "新建设计",
    role: [1, 2, 3, 4],
    to: "/createdesign",
  },
  {
    index: 4,
    icon: "iconfont icon-Copyright-images-unselection",
    title: "版权图片",
    role: [1, 2, 3, 4],
    to: "/copyright",
  },
  // {
  //   icon: "iconfont icon-Copyright-images-unselection",
  //   title: "版权图片",
  //   role: [1, 2, 3, 4],
  //   skip: true, // 跳转主网站
  //   to: "/photo",
  // },
  {
    index: 5,
    icon: "iconfont icon-moban2",
    title: "模板中心",
    role: [1, 2, 3, 4],
    to: "/templateCenter",
  },
  {
    index: 6,
    icon: "iconfont icon-Side-navi-Brand-unselection",
    title: "品牌管理",
    role: [1, 2, 3, 4],
    to: "/brand",
  },
  {
    index: 7,
    icon: "iconfont icon-Side-navi-teamadmin-unselection",
    title: "成员管理",
    role: [1, 2, 3, 4],
    to: "/member",
  },
  {
    index: 8,
    icon: "iconfont icon-shuju-moren",
    title: "数据统计",
    role: [1, 2],
    to: "/statistics",
  },
  {
    index: 9,
    icon: "iconfont icon-weibiaoti-1-02",
    title: "首页管理",
    role: [1, 2, 3],
    to: "/home-setting",
  },
];
