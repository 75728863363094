<template>
  <div class="left-nav">
    <div class="team-info">
      <el-popover placement="right-end" width="200" v-model="visible">
        <el-scrollbar style="height: 100%; overflow-x: hidden; width: 200px">
          <ul class="team-ul" style="max-height: 450px">
            <li v-for="team in teamList" :key="team.id" class="text-hidden" :class="loginInfo.current_team_id === team.id ? 'active' : ''" @click.stop="command(team)">
              {{ team.name }}
            </li>
            <li
              class="team-item"
              id="create-item"
              v-if="loginInfo.create_team == 1"
              @click="command(0)"
            >
              <span class="iconfont icon-Side-navi-Newteam-unselection"></span
              >创建团队
            </li>
          </ul>
        </el-scrollbar>

        <div slot="reference" style="height: 70px; position: relative; cursor: pointer">
          <p style="display: flex">
            <el-tooltip :content="loginInfo.current_team_name" placement="top" effect="light">
              <span class="text-hidden" style="width: 135px; text-align: left; display: block">{{ loginInfo.current_team_name }}</span>
            </el-tooltip>
            <i class="iconfont toggle-team"></i>
          </p>
          <span style="font-size: 12px; line-height: 20px; position: absolute; left: 0; bottom: 10px; color: #5c2df9"> 切换其他团队 </span>
        </div>
      </el-popover>
      <ul>
        <li
          v-for="(item, index) in nav"
          :key="item.title"
          :class="{
            'leftnav-submenu': item.submenu && item.submenu.length > 0,
          }"
          @click="showsubmenu(item, index)"
        >
          <template v-if="item.skip">
            <a :href="`${href}${item.to}`" :target="item.title == '版权图片' ? '_blank' : ''">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </a>
          </template>
          <template v-else>
            <router-link :to="item.to" v-if="!(item.submenu && item.submenu.length)">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </router-link>

            <template v-else>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
              <i :class="[item.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i>
              <el-collapse-transition>
                <ul class="submenulist" v-show="item.show" @click.stop="show(item, index)">
                  <li class="submenu-item transition-box" v-for="submenuitem in item.submenu" :key="submenuitem.title">
                    <template v-if="submenuitem.skip">
                      <a :href="`${href}${submenuitem.to}`"
                        ><span>{{ submenuitem.title }}</span></a
                      >
                    </template>
                    <template v-else>
                      <router-link :to="submenuitem.to">
                        <span>{{ submenuitem.title }}</span>
                      </router-link>
                    </template>
                  </li>
                </ul>
              </el-collapse-transition>
            </template>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import nav from "../../config/nav";
import { changeTeam } from "@/config/axios/new_api";
export default {
  name: "LeftNav",
  data() {
    return {
      href: process.env.VUE_APP_BaseHref,
      navMenu: nav,
      activeIndex: 1,
    };
  },
  computed: {
    teamList() {
      return this.$store.getters.teamList;
    },
    loginInfo() {
      return this.$store.getters.loginInfo;
    },
    nav() {
      let navList = nav.filter((item) => item.role.includes(this.loginInfo.current_team_role));
      return navList;
    },
  },
  created() {
    this.updatePageState(this.$route);
  },
  methods: {
    show(item, index) {
      item.show = true;
      // this.$set(this.nav, index,)
    },
    command(info) {
      if (info === 0) {
        // info 为0  代表需要创建团队
        // 创建团队因素： 充值自动创建团队
        //this.staticFunc.toHref(this.href + "/companyvip");
        let url = process.env.VUE_APP_BASELogout;
        window.open(url.substr(0, url.length - 6));
      } else {
        changeTeam({
          team_id: info.id,
        }).then((res) => {
          if (res.status === 1) {
            if (process.env.NODE_ENV !== "production") {
              //return
              this.$router.go(0);
            } else {
              let domain = info.domain;
              if (process.env.VUE_APP_RealmName) {
                // 配置域名
                domain = "https://" + domain.split("//")[1].split(".")[0] + `.${process.env.VUE_APP_RealmName}`;
              }
              this.staticFunc.toHref(domain);
            //   window.open(domain);
            }
          }
        });
      }
    },
    showsubmenu(item, index) {
      let { show } = item;
      this.$set(this.nav[index], "show", !show);
    },
    updatePageState(val) {
      let _this = this;

      _this.nav.forEach((item, index) => {
        if (item.submenu && item.submenu.length > 0) {
          //item.show = false;
          item.submenu.forEach((subitem) => {
            if (subitem.to === val.path && !item.show) {
              let show = item.show;
              _this.$set(_this.nav[index], "show", true);
            }
          });
        }
      });
    },
  },
  watch: {
    $route: "updatePageState",
  },
};
</script>

<style lang="scss" scoped>
.left-nav {
  @include wh(200px, 100%);
  min-height:100%;
  max-height: 100%;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 60px;
  background: #f7f6f9;
  z-index: 200;
  padding: 13px 0 73px;
  .toggle-team {
    background: url("../../assets/image/toggle-item.png") no-repeat center;
    background-size: 100% 100%;
    @include wh(20px, 20px);
    float: right;
    margin: 16px;
    cursor: pointer;
  }

  .team-info {
    color: #3c3c43;
    line-height: 50px;
    width: 100%;
    text-indent: 48px;

    ul {
      font-size: 14px;

      a {
        color: #3c3c43;
        display: block;
        user-select: none;
        @include wh(100%, 50px);

        i {
          margin-right: 8px;
          font-size: 14px;
        }

        &:hover {
          background: #fff;
        }

        &.router-link-active {
          background: #fff;
          color: #5a2df5;

          .iconfont {
            color: #5a2df5;
          }
        }
      }

      li {
        user-select: none;
        cursor: pointer;
        i {
          font-size: 14px;
        }
        [class*="el-icon"] {
          font-size: 14px;
          display: inline;
        }

        i:nth-of-type(1) {
          margin-right: 8px;
        }

        i:nth-of-type(2) {
          margin-left: 22px;
        }
      }
    }
    .submenulist {
      li {
        a {
          text-indent: 70px;
          span {
            color: #838089;
          }

          &.router-link-active {
            span {
              color: #5a2df5;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.logo-upload-box {
  @include wh(272px, 252px);
  background: #ffffff;
  border-radius: 8px;
  padding: 10px 16px 20px;

  .logo-upload {
    @include wh(232px, 88px);

    .el-upload--picture-card {
      @include wh(100%, 100%);
      line-height: 100px;
      background: #ddd;
      border: 1px dashed #f3f3f3;
      position: relative;
      overflow: hidden;

      .upload-hover {
        @include wh(232px, 88px);
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        line-height: 88px;
        display: none;
      }

      &:hover {
        .upload-hover {
          display: block;
        }
      }
    }
  }

  .el-form-item {
    margin-bottom: 10px;
  }
}

.left-team-con {
  margin-top: 0 !important;
}

.team-ul {
  width: 180px;
  line-height: 44px;
  padding: 0 12px;

  li {
    border-bottom: 1px solid #e7e7e7;
    position: relative;
    text-align: center;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &.active {
      color: #7966ff;
    }

    .iconfont {
      position: absolute;
      top: 14px;
      left: 16px;
      line-height: 16px;
    }
  }
}
</style>
