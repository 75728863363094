<template>
  <div class="wechat-login-wrapper">
    <div class="qrcode-wrapper">
      <img class="qrcode-img" :src="wechatForm.url" alt="" />
      <div class="warning-mask" v-if="expireStatus">
        <img src="@/assets/image/logindialog/overduewarning.png" alt="" />
        <p>二维码已过期</p>
        <el-button type="text" @click="getWechatCode">点击刷新</el-button>
      </div>
      <div class="decoration"  @click="changeWayType">
        <img src="@/assets/image/logindialog/loginswitch.png" alt="" />
      </div>
    </div>
    <div class="wechat-login-hint-font">首次登录需关注“爱设计作图”公众号</div>
  </div>
</template>

<script>
import { Login } from "@/config/axios/landing.js";
import { mapActions } from "vuex";
let production = process.env.NODE_ENV === "production";
export default {
  name: "wechatLogin",
  props:{
    'wayType':String,
  },
  data() {
    return {
      wechatForm: {},
      getWeixinLogin: null,
      expireStatus: false, // 过期后显示过期图片
      loginParams: {
        // 后期用于统计注册操作 来源主要通过url
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
      },
    };
  },
  created() {
    let { utm_source, utm_medium, utm_campaign } = this.$route.query;
    this.loginParams = {
      utm_source: utm_source || "",
      utm_medium: utm_medium || "",
      utm_campaign: utm_campaign || "",
    };
  },
  mounted() {
    this.getWechatCode();
  },
  beforeDestroy() {
    clearInterval(this.getWeixinLogin);
    this.getWeixinLogin = null;
  },
  methods: {
    getWechatCode() {
      let that = this;
      let timeout = 0;
      clearInterval(this.getWeixinLogin);
      this.getWeixinLogin = null;
      Login.wechatCode(this.loginParams).then((res) => {
        if (res.status === "1") {
          this.expireStatus = false;
          this.wechatForm = res.data;
          var _data_sign = res.data.sign;
          this.getWeixinLogin = setInterval(() => {
            getWeixinLoginResult(_data_sign);
          }, 1000);
        }
      });

      function getWeixinLoginResult(_sign) {
        Login.wechatLogin({
          sign: _sign,
          form_validate: true,
        }).then(async (res) => {
          if (res.status === 1) {
            clearInterval(that.getWeixinLogin);
            that.$message.success("登录成功");
            // that.$store.commit("loginInfo", res.data.user);
            that.$parent.$parent.showLogin=false;
            if (!production) {
              that.staticFunc.setCookie("user_uuid", res.data.user.uuid);
            }
            await that.loginInfo();
            that.$parent.afterLoginOperate();
          } else {
            timeout += 1000;
            if (timeout >= 1000 * 300) {
              // 300s 过期时间
              clearInterval(that.getWeixinLogin);
              that.expireStatus = true;
            }
          }
        });
      }
    },
    changeWayType(){
      this.$emit('update:wayType','account')
    },
    ...mapActions(["loginInfo"]),
  },
};
</script>

<style lang="scss" scoped>
.wechat-login-wrapper {
  width: 100%;
  padding-top: 21px;
  .qrcode-wrapper {
    position: relative;
    width: 177px;
    height: 175px;
    background: #FFFFFF;
    margin: auto;
    padding: 25px 24px 20px 24px;
    box-shadow: 0px 0px 10px 0px rgba(183, 183, 183,0.5);
    .qrcode-img {
         width: 129px;
      height: 130px;
      display: block;
      z-index: 10000;
      position: absolute;
    }
    .warning-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #e7e7e6;
      box-sizing: border-box;
      text-align: center;
      img {
        display: block;
        width: 36px;
        height: 36px;
        margin: 50px auto 10px;
      }
      p {
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #000;
        line-height: 20px;
      }
    }
    .decoration {
      box-sizing: border-box;
      position: absolute;
      top: 11px;
      right: 6px;
      cursor: pointer;
      &::before{
        content: '';
        width: 0;
        height: 0;
        border: 26px solid transparent;
        border-bottom: 31px solid #fff;
        border-left: 0;
        position: absolute;
        top: -29px;
      }
      img{
        width: 33px;
      }
     
    }
  }
  .wechat-login-hint-font {
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3b3946;
    line-height: 17px;
    margin-top: 18px;
  }
}
</style>
