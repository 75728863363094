<template>
  <div id="header">
    <div class="header-left">
      <router-link :to="loginInfo && loginInfo.is_open === 2 ? '/home' : '/template'" class="logo-href">
        <template v-if="logoForm.logo">
          <img :src="logoForm.logo" width="100" height="40" />
        </template>
        <template v-else>
          <img src="@/assets/image/logo.png" width="100" height="40" />
        </template>
      </router-link>
      <div class="header-nav">
        <router-link to="/home" v-if="loginInfo && loginInfo.is_open === 2" :class="$route.path === '/' || $route.path === '/home' ? 'active' : ''">首页</router-link>
        <!-- <a :href="`${edithref}/?f=bz&k=/f=isheji`" target="_blank">365编辑器</a> -->
      </div>
      <div class="header-right_nav" :style="{ right: loginInfo ? '240px' : '310px' }">
        <router-link to="/copyright">版权图片</router-link>
        <router-link to="/templateCenter">模板中心</router-link>
      </div>
    </div>

    <div class="header-right">
      <div class="vip-item" @click="goLand">企业VIP</div>
      <div class="manage-nav">
        <el-dropdown size="mini" trigger="click" @command="command">
          <span style="color: #3b3946">管理</span>
          <el-dropdown-menu slot="dropdown" class="manage-info">
            <template v-for="(item, key) in manageNav">
              <template v-if="!item.hidden && !item.submenu">
                <el-dropdown-item :key="key" :command="item.to" :class="item.to === $route.path ? 'active' : ''">
                  <i :class="item.icon"></i>
                  <span>{{ item.title }}</span>
                </el-dropdown-item>
              </template>
              <template v-if="item.submenu && item.submenu.length > 0">
                <el-dropdown-item :key="submuenitenkey" :command="submenuiten.to" :class="submenuiten.to === $route.path ? 'active' : ''" v-for="(submenuiten, submuenitenkey) in item.submenu">
                  <i :class="submenuiten.icon"></i>
                  <span>{{ submenuiten.title }}</span>
                </el-dropdown-item>
              </template>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="login-button" v-if="!loginInfo" @click="toCompany">登录 | 注册</div>
      <div class="figure" v-if="loginInfo">
        <el-dropdown size="mini" trigger="click" @command="userInfoCommand">
          <el-avatar :size="45" split-button type="primary" :src="circleUrl"> </el-avatar>
          <el-dropdown-menu slot="dropdown" class="avator-info">
            <el-dropdown-item class="avator-name">
              <span>{{ loginInfo && loginInfo.nick_name }}</span>
              <p v-if="loginInfo.is_company">
                <img src="@/assets/image/vippro.png" alt="" />
                <span>企业VIP</span>
              </p>
            </el-dropdown-item>
            <el-dropdown-item command="setting-user">
              <i class="iconfont icon-wode"></i>
              <span>账号设置</span>
            </el-dropdown-item>
            <el-dropdown-item command="setting-safe" v-if="!currentTeam.corp_id">
              <i class="iconfont icon-zhanghaoanquan"></i>
              <span>账号安全</span>
            </el-dropdown-item>
            <el-dropdown-item command="setting-auth">
              <i class="iconfont icon-Authorize"></i>
              <span>授权记录</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout">
              <i class="iconfont icon-tuichudenglu"></i>
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- <login-dialog v-if="showLogin" @close="showLogin = false" /> -->
    </div>
  </div>
</template>

<script>
import nav from "../../config/nav";
import loginDialog from "@/components/common/loginDialog";
import tokenFunc from "@/config/static/token";
export default {
  data() {
    return {
      href: process.env.VUE_APP_BaseHref,
      showLogin: false,
      num: 0,
      edithref: process.env.VUE_APP_EditorHref,
    };
  },
  components: {
    loginDialog,
  },
  computed: {
    logoForm() {
      return this.$store.getters.logoForm;
    },
    loginInfo() {
      return this.$store.getters.loginInfo;
    },
    circleUrl() {
      return (this.loginInfo && this.loginInfo.image_url) || require("@/assets/image/mobile_head.png");
    },
    manageNav() {
      let navList = nav.filter((item) => item.role.includes(this.loginInfo && this.loginInfo.current_team_role));
      return navList;
    },
    currentTeam() {
      return this.$store.getters.currentTeam;
    },
  },
  methods: {
    goLand() {
      let url = process.env.VUE_APP_BASELogout;
      window.open(url.substr(0, url.length - 6));
    },
    toCompany() {
      this.num = 0;
      this.showLogin = true;
    },
    command(info) {
      if (info === "/team/photo") {
        window.open(this.href + info);
        //window.location.href = this.href + info;
      } else {
        this.$router.push({ path: info });
      }
    },
    userInfoCommand(info) {
      if (info === "logout") {
        var access_token = tokenFunc.getToken();
        if (access_token) {
          loginSdk
            .requestAction("logout", { token: access_token })
            .then((res) => {
              if (res.code == 0) {
                window.sessionStorage.clear();
                this.$store.commit("loginInfo", null);

                window.location.href = `${window.location.origin}/login?type=logout`;
              }
            })
            .catch((error) => {});
        } else {
          window.sessionStorage.clear();
          this.$store.commit("loginInfo", null);
          this.staticFunc.toHref(process.env.VUE_APP_BASELogout);
        }
      } else if (info === "setting-safe" || info === "setting-user" || info === "setting-auth") {
        this.$router.push({
          name: "User",
          query: { info: info.split("-")[1] },
        });
      }
    },
  },
};
</script>

<style lang="scss">
#header {
  @include wh(100vw, 60px);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);
  padding: 10px 50px;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 100;
  .header-left {
    .logo-href {
      @include wh(100px, 40px);
      float: left;
      img {
        max-width: 100%;
      }
    }
    .header-nav,
    .header-right_nav {
      float: left;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      color: #3b3946;
      line-height: 40px;
      a {
        color: #3b3946;
        padding: 0 15px;
        &:first-child {
          margin-left: 20px;
        }
        &.active {
          color: $activeColor;
        }
      }
    }
    .header-right_nav {
      position: absolute;
      right: 240px;
    }
  }
  .header-nav {
    float: left;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    color: #3b3946;
    line-height: 40px;
    a {
      color: #3b3946;
      padding: 0 15px;
      &:first-child {
        margin-left: 20px;
      }
      .vip-item {
        float: left;
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3b3946;
        line-height: 20px;
        padding: 10px 14px;
        display: block;
        margin-top: 11px;
        .time-limit-icon {
          width: 28px;
          margin-left: 0;
          vertical-align: middle;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .login-button {
        float: left;
        width: 100px;
        height: 40px;
        background: #5a2df5;
        border-radius: 8px;
        margin-left: 14px;
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        position: relative;
        cursor: pointer;
      }
      .figure {
        cursor: pointer;
        float: left;
        margin-top: 8px;
        > img {
          @include wh(45px, 45px);
          border-radius: 50%;
        }
      }
      .manage-nav {
        margin-right: 15px;
        padding: 0 15px;
        height: 60px;
        line-height: 60px;
        float: left;
        .el-dropdown {
          cursor: pointer;
        }
      }
    }
  }
}
.header-right {
  float: right;
  position: relative;
  top: -10px;
  .sj_icon {
    float: left;
    height: 60px;
    margin-right: 14px;
  }
  .vip-item {
    float: left;
    height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3b3946;
    line-height: 20px;
    padding: 10px 14px;
    display: block;
    margin-top: 12px;
    .time-limit-icon {
      width: 28px;
      margin-left: 0;
      vertical-align: middle;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .login-button {
    float: left;
    width: 100px;
    height: 40px;
    background: #5a2df5;
    border-radius: 8px;
    margin-left: 14px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    position: relative;
    cursor: pointer;
  }
  .figure {
    cursor: pointer;
    float: left;
    margin-top: 8px;
    > img {
      @include wh(45px, 45px);
      border-radius: 50%;
    }
  }
  .manage-nav {
    margin-right: 15px;
    padding: 0 15px;
    height: 60px;
    line-height: 60px;
    float: left;
    .el-dropdown {
      cursor: pointer;
    }
  }
}
.manage-info.el-popper {
  @include wh(190px, auto);
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  margin-top: 0 !important;
  &.el-popper .popper__arrow {
    display: none;
  }
  li {
    @include wh(100%, 48px);
    line-height: 48px;
    font-size: 14px;
    color: #333;
    &:hover,
    &.active {
      background: #f7f7f7;
      color: #333;
    }
  }
}
.avator-info {
  @include wh(220px, auto);
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  padding: 0;
  margin-top: 0 !important;
  &.el-popper .popper__arrow {
    display: none;
  }
  > li:first-child {
    @include wh(100%, 70px);
    // background: linear-gradient(213deg, #7b788d 0%, #3b3946 100%);
    background-image: url("../../assets/image/Oval.png");
    background-size: 100% 100%;
    border-radius: 4px 4px 0px 0px;
    // line-height: 70px;
    color: #fff;
    margin-bottom: 8px;
    font-size: 14px;
    // padding: 0;
    margin-top: 0 !important;
    &.el-popper .popper__arrow {
      display: none;
    }
  }
  > li:first-child {
    @include wh(100%, 70px);
    // background: linear-gradient(213deg, #7b788d 0%, #3b3946 100%);
    background-image: url("../../assets/image/Oval.png");
    background-size: 100% 100%;
    border-radius: 4px 4px 0px 0px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 8px;
    font-size: 14px;
    padding-top: 11px;
    padding-bottom: 12px;
    padding-left: 16px;
    &:hover {
      color: #fff;
    }
  }
  > li:not(:first-child) {
    @include wh(100%, 48px);
    line-height: 48px;
    font-size: 14px;
    color: #333;
    &:hover {
      background: #f7f7f7;
      color: #333;
    }
  }
}
.avator-name {
  p {
    display: flex;
    align-items: center;
    margin-top: 7px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}
</style>
<style lang="scss" scoped>
 ::v-deep(.el-avatar) {
  img {
    width: 100%;
  }
}
</style>
